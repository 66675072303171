// colors from materialize
//$primary-color, $secondary-color


@import "../components/color-variables";

$caption: color("brown", "lighten-5") !default;


// timeline

$numDots: 6;
$parentWidthBase: 0.8;
$parentWidth: $parentWidthBase * 100vw;
$parentMaxWidth: 1000px;
$dotWidth: 25px;
//$active: #2c3e50;
$inactive: #aeb6bf;
$active: $secondary-color;


.flex-parent {
  // display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.input-flex-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  // width: $parentWidth;
 // max-width: $parentMaxWidth;
  position: relative;
  z-index: 0;
  // margin-left: calc((#{$parentWidth} - #{$dotWidth}) / #{$numDots});

  // margin-left: calc((#{$parentWidth}) / #{$numDots});

  margin-top: 6rem;
}



.input-flex-container input:not(:checked), .input-flex-container input:checked {
	position: relative;
	cursor: pointer;
	opacity: 1;
	pointer-events: visible;

  left: #{$dotWidth};
}

input {
  width: $dotWidth;
  height: $dotWidth;
  background-color: $active;
  position: relative;
  border-radius: 50%;
  display: block;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &::before, &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    background-color: $active;
    width: $parentWidth / $numDots;
    height: 5px;
    max-width: $parentMaxWidth / $numDots;
  }
  &::before {
    left: calc(#{-$parentWidth / $numDots} + #{$dotWidth / 2});
  }
  &::after {
    right: calc(#{-$parentWidth / $numDots} + #{$dotWidth / 2});
  }
  &:checked {
    background-color: $active;
    &::before {
      background-color: $active;
    }
    &::after {
      background-color: $inactive;
    }
  }
  &:checked {
    ~ input {
      &, &::before, &::after {
        background-color: $inactive;
      }
    }
    + .dot-info {
      span {
        font-size: 1.1rem;
        font-weight: bold;
      }
    }
  }
}

.dot-info {
  width: $dotWidth;
  height: $dotWidth;
  display: block;
  // background-color: red
  visibility: hidden;
  position: relative;
  z-index: -1;
  //position each span on top of the dot immediately before it; -1px at the end is just fudging the numbers for rounding error
 
  //left: calc((((#{$parentWidth} - #{$dotWidth}) / #{$numDots}) * -1) - 1px);
  left: calc((((#{$parentWidth} - #{$dotWidth}) / #{$numDots}) * -0.5) - 1px);

  span {
    visibility: visible;
    position: absolute;
    font-size: 1rem;
    &.year {
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
    }
    &.label {
      //top: -65px;
      //left: 0;
      top: -4rem;
      left: 4rem;
      transform: rotateZ(-45deg);
      width: 70px;
      text-indent: -10px;
    }
  }
}

#timeline-descriptions-wrapper {
  width: 100%;
//  margin-top: 140px;

  margin-top: 4rem;
  font-size: 22px;
  font-weight: 400;
//  margin-left: calc((-#{$parentWidth} - #{$dotWidth}) / #{$numDots});
//  p {
//    margin-top: 0;
//    display: none;
//  }

  div {
    margin-top: 0;
    display: none;
  }



	ul {
		list-style-type: none;
		counter-reset: li;
		text-align: left;
	}

	ul > li::before {
		counter-increment: li;
		content: counter(li, decimal-leading-zero) ". ";
		color: $secondary-color;
		margin-right: 0.25em;
	}

  li {
    font-size: 70%;
    list-style-type: none;
  }
}

// magic shenanigans
// $descriptions: "1910", "1920", "1930", "1940", "1950", "1960", "1970", "1980", "1990", "2000";

$descriptions: "t0", "t1", "t2", "t3", "t4", "t5", "t6";



@each $desc in $descriptions {
  input[data-description="#{$desc}"]:checked ~ #timeline-descriptions-wrapper {
    // p[data-description="#{$desc}"] {
    div[data-description="#{$desc}"] {
      display: block;

      background: $caption;
      padding: 1rem;
      border-radius: 6px;
    }
  }
}






@media (min-width: $parentMaxWidth / $parentWidthBase) {

  // fix starting point and last point
  // use stations named as t0,.. t5...

  input[data-description="t#{$numDots - 1}"]:after {
      width: 0px;
      left: 0;
    }

  input[data-description="t0"]:before {
      width: 0px;
      left: 0;
    }


  .input-flex-container {
   
   // !important wrap all timeline in section container > or uncomment this line
   // margin-left: #{$parentMaxWidth / $numDots + $dotWidth / 2};
  }
  input {
    &::before {
      left: #{-($parentMaxWidth / $numDots) + ($dotWidth / 2)};
    }
    &::after {
      right: #{-($parentMaxWidth / $numDots) + ($dotWidth / 2)};
    }
  }
  .dot-info {
   // left: calc((((#{$parentMaxWidth} - #{$dotWidth}) / #{$numDots}) * -1) - 1px);
   
   left: calc((((#{$parentMaxWidth} - #{$dotWidth}) / #{$numDots}) * -0.5) - 1px);
   //   left: calc((((#{$parentMaxWidth} - #{$dotWidth}) / #{$numDots}) * -1) + (#{$dotWidth} * (#{$numDots} + 1)  );
  } 
//  #timeline-descriptions-wrapper {
//    margin-left: #{-($parentMaxWidth / $numDots) + $dotWidth / 2};
//  }
}

@media (max-width: 630px) {
  .flex-parent {
    justify-content: initial;
  }
  .input-flex-container {
    flex-wrap: wrap;
    justify-content: center;
    width: 400px;
    height: auto;
    margin-top: 15vh;
    margin-left: 0;
  }
  input, .dot-info {
    width: 60px;
    height: 60px;
    margin: 0 10px 50px;
  }
  input {
    background-color: transparent !important;
    z-index: 1;
    &::before, &::after {
      content: none;
    }
    &:checked {
      + .dot-info {
        background-color: $active;
        span {
          &.year {
            font-size: 1rem;
          }
          &.label {
            font-size: 1rem;;
          }
        }
      }
    }
  }
  .dot-info {
    visibility: visible;
    border-radius: 50%;
    z-index: 0;
    left: 0;
    margin-left: -70px;
    background-color: $inactive;
    span {
      &.year {
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ecf0f1;
      }
      &.label {
        top: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        text-indent: 0;
        text-align: center;
      }
    }
  }
  #timeline-descriptions-wrapper {
    margin-top: 3rem;
    margin-left: 0;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .input-flex-container {
    width: 340px;
  }
}

@media (max-width: 400px) {
  .input-flex-container {
    width: 300px;
  }
}