/* intro and story */

.story {
    position: relative;
    height:100%;
}


.story:before {
    // background: rgba(41,181,139,0.6);
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all .3s linear;
    height:50vh;
}


.story .payoff {
    position: absolute;
    padding-top: 5rem;
    padding-left: 3rem;
    max-width: 60%;
}

.story img {
    width: 100%;
}

.story .js-chickendinner-bg {
    height: 50vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity:1;
    border-bottom: 1px solid #212121;
}

.story .js-chickendinner-bg:before {
    background: rgba(41,181,139,1);
}

.story {
    display:block;
   -webkit-text-stroke-width: 0.01rem;
   -webkit-text-stroke-color: white;
}


.story h1 {
    
    display: inline;
    color: #212121;
    font-weight: 500;
    text-shadow: 1px 1px 2px rgba(255,255,255,0.1), 1px 1px 2px rgba(255,255,255,1);
    mix-blend-mode: luminosity;
    /*background: rgba(41,181,139,0.2);*/
}

.story h2 { 
    display: inline;
    color: white;
    font-weight: 400;
    text-shadow: 1px 1px 2px rgba(255,255,255,0.1), 1px 1px 2px rgb(255, 255, 255);
     -webkit-text-stroke-width: 0.1rem;
     -webkit-text-stroke-color: #2121;
    background: rgba(41,181,139,1);
    mix-blend-mode: luminosity;
    line-height: 4.36rem;
}

.story h1:before {
    content: ' '; display: block; white-space:pre;
}

.story h2:before {
    content: ' '; display: block; white-space:pre;
}

@media (max-width: 599px) {

    .story .payoff {
        max-width: 100%;
        padding-right: 4rem;
        padding-left: 4rem;
        padding-top: 3rem;

    }

    .story h1 {
        font-size: 3.2rem;
    }

    .story h2 {
        font-size: 2.1rem;
        line-height: 2.6rem;
    }

}


@media (min-width: 600px) {

    .story .payoff {
        max-width: 90%;
        padding-right: 5rem;
    }

}

@media (min-width: 769px) {

    .story .payoff {
        max-width: 80%;
        padding-right: 5rem;
    }


}

@media (min-width: 1024px) {

    .story .payoff {
        max-width: 60%;
    }


}

@media (min-width: 1281px) {

    .story .payoff {
        max-width: 50%;
    }


}



/* end intro */


/* canvas */
#my_container {
    z-index: -1;   
}

canvas {
    max-height: 80vh;
    width: 100vw;
}
