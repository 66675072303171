.c-foreground {
    @extend .blue, .lighten-4;
  }


.cta {
	 @extend .green, .accent-3;
}

.caption {
	 @extend .brown, .lighten-5;
}