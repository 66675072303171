// custom

@import "timeline/timeline";
@import "components/_variables";

// typography
h1 {
  font-size: 3.8rem;
  font-weight: 600;
}


body {
  font-family: Roboto;
}

article p {
  font-weight: 300;
}

h2:before {
  content : '> ';
  color: #ff5722; //deep-orange
}

.brand-logo img {
  height: 64px;
}

@media (max-width: 600px) {
  .brand-logo img {
    height: 56px;
  }
}


article section ul:not(.browser-default)>li {
    list-style-type: initial;
}

footer h2 {
  font-size: 2.56rem;
}

footer h3 {
  font-size: 1.56rem;
}

footer a {
  color: $secondary-color;
}


// blockquote intro
blockquote.payoff {
    border-left: none;
    padding-left: 0;
    text-align: end;
}

blockquote.payoff h3 {
    display: inline;
    color: white;
    font-weight: 400;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.5), 1px 1px 2px #fff;
    -webkit-text-stroke-width: 0.1rem;
    -webkit-text-stroke-color: #2121;
    mix-blend-mode: luminosity;
    line-height: 2rem;
    font-size: 1.92rem;
    background: rgba(41, 181, 139, 0.2);
}

blockquote.payoff h3 strong {
    text-shadow: 1px 1px 2px rgba(255,255,255,0.5), 1px 1px 2px #fff;
    color: #ff5722; //deep-orange
}

// sidenav

.sidenav h6 {
  margin-left:10px;
  font-variant-caps: all-small-caps;
}

.sidenav li {
  background: white;
}

// canvas

canvas {
  background-image: linear-gradient(45deg, rgba(41,181,139,0.6),  orange)
}

// menu

.logo {
    background: url(/assets/images/logos/LOGO_NIFTY_512.png);
    width: 66px;
    height: auto;
    background-size: 100%;
    background-repeat: no-repeat;
}

a.logo span {
    line-height: 1rem;
    font-size: .8rem;
    position: absolute;
    left: 90px;
    font-weight: 400;
    text-transform: uppercase;
    top: 1.3rem;
}

a.logo span:last-child {
    font-size: .7rem;
    font-weight: 100;
    padding-top: 1rem;
}

// tags
.chip p {
  margin: 0;
  font-weight: 600;
}


// carousel full page
.carousel h5 {
  font-style: italic;
  margin: 0;
  padding-left: 0;
  border-left: none;
}

.carousel-background-image { 
    background-repeat:no-repeat;
    background-size:cover;
    //filter: sepia(100%) hue-rotate(180deg) saturate(400%) opacity(0.8); 
    filter: contrast(125%) brightness(105%) grayscale(0.3) opacity(0.8);
}

.carousel-background-image h5 { 
    filter: sepia(100%) hue-rotate(180deg) saturate(100%) opacity(1); 
    
    font-weight: 400;
    font-size:160%;
    text-shadow: 1px 1px 2px rgba(255,255,255,0.1), 1px 1px 2px #fff;
    -webkit-text-stroke-width: 0.1rem;
    -webkit-text-stroke-color: #2121;
    //background: #29b58b;
    mix-blend-mode: luminosity;
    line-height: 3.1rem;
    color: black;
}

.carousel-background-image h5:after {
    content: '"';
    font-size: 160%;
}

.carousel-background-image h5:before {
    content: '"';
    font-size: 160%;
}

.carousel-background-image p {
    font-size: 160%;
}


.carousel-background-image h6 {
  font-size: 1.92rem;
}

.carousel p {
  margin: 0;
}

// games

.start-node-mask {
   -webkit-filter: invert(0.2) grayscale(0.3);
    -ms-filter: invert(0.2) grayscale(0.3);
    filter: invert(0.2) grayscale(0.3);


}

.end-node-mask {
   -webkit-filter: invert(0.2) grayscale(0.3);
    -ms-filter: invert(0.2) grayscale(0.3);
    filter: invert(0.2) grayscale(0.3);

    position: absolute !important;
    -webkit-clip-path: polygon(100% 0%, 100% 100%, 40% 100%, 60% 0%);
    clip-path: polygon(100% 0%, 100% 100%, 40% 100%, 60% 0%);
}

.start-title::before {
    content: 'from ';
    font-family:'Source Sans Pro','Gill-sans',sans-serif;
    text-rendering: geometricPrecision;
    font-size: 1.2rem;
    color: white;
}

.end-title::before {
    content: 'to ';
    font-family:'Source Sans Pro','Gill-sans',sans-serif;
    text-rendering: geometricPrecision;
    font-size: 1.2rem;
    color: white;
}

.gamification .carousel .carousel-item {
    visibility: hidden;
    width: 260px;
    height: 200px;
}

.gamification .carousel {
    height: 650px;
    top: -100px;
}

.gamification .card .card-title {
    font-size: 1.6rem;
    line-height: 1.6rem;
}


.gamification .card .card-image img {
    object-fit: fill;
    /* width: 400px; */
    height: calc(3/4 * 260px); // heigh of carousel-item
}

.gamification .carousel .indicators .indicator-item {
  background-color: $secondary-color-light;
}

.gamification .carousel .indicators .indicator-item.active {
  background-color: $secondary-color;
}


// figures

figure p {
  margin: 0;
}

figure p img {
  outline: 1px solid gray;
}

figcaption p {
  font-size: 1rem;
  font-weight: 100;
  font-family: Roboto, Verdana, Arial; 
}